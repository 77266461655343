<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="充值"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-grid clickable :gutter="20" :column-num="3" class="mt-4">
      <van-grid-item
        v-for="(fee, index) in state.rechargeOptions"
        :key="index"
        :class="index == state.selectIndex ? 'selected' : 'unselected'"
        @click="changeSelect(index)"
      >
        <div>{{ fee }}元</div>
      </van-grid-item>
    </van-grid>
    <div class="px-20 mt-4">
      <van-field
        v-model="state.amount"
        type="number"
        maxlength="6"
        input-align="center"
        label=""
        placeholder="点此输入自定义金额"
        @keyup="customfee"
      />
    </div>
    <div class="pt-4 action-button-area text-center">
      <van-button type="success" icon="wechat-pay" @click="wxpay"
        >微信支付{{ state.finalPayFee }}元</van-button
      >
    </div>
    <div
      v-if="MODULES.ICBC_DIGITAL_PAYMENT"
      class="action-button-area text-center"
    >
      <van-button @click="icbcpay">
        <template #icon>
          <van-icon :name="require('@/assets/images/icon-ecny.png')" />
        </template>
        数字人民币e-CNY支付</van-button
      >
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { MODULES } from "@/config/config.local";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { Field, Notify, Toast, Grid, GridItem } from "vant";
import {
  signaturePack,
  prepareWechatRecharge,
  prepareIcbcRecharge,
} from "@/api/wechat.service";
export default {
  name: "Recharge",
  components: {
    [Field.name]: Field,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },

  setup() {
    const router = useRouter();
    const state = reactive({
      amount: "",
      selectIndex: 0,
      rechargeOptions: [10, 20, 30, 50, 100, 200],
      finalPayFee: 10,
    });

    const changeSelect = (index) => {
      state.amount = "";
      state.selectIndex = index;
      state.finalPayFee = state.rechargeOptions[state.selectIndex];
    };

    const customfee = () => {
      state.selectIndex = -1;
      const fee = new Number(state.amount).toFixed(2);
      if (fee > 0) {
        state.finalPayFee = fee;
      } else {
        state.amount = "";
        state.finalPayFee = 0;
      }
    };

    const wxpay = async () => {
      if (state.finalPayFee == "0.00" || state.finalPayFee <= 0) {
        Toast({ message: "请选择或输入充值金额", position: "bottom" });
        return;
      }
      // 必须加入提示, 预下单网络延时较大时友好提醒用户
      Toast.loading({
        message: "请稍后...",
        forbidClick: true,
        duration: 0,
      });
      try {
        const { result } = await prepareWechatRecharge(state.finalPayFee * 100);
        Toast.clear();
        wx.chooseWXPay({
          timestamp: result.timeStamp,
          nonceStr: result.nonceStr,
          package: result.package,
          signType: result.signType,
          paySign: result.paySign,
          success: () => {
            router.back();
          },
          error: (res) => {
            Notify({
              type: "danger",
              message: res.errMsg ? res.errMsg : "支付失败",
            });
          },
          cancel: () => {
            Toast("已取消");
          },
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({
          message: msg ? msg : "前端脚本异常/预下单失败",
          position: "bottom",
        });
        if (!msg) console.log(error);
      }
    };

    const icbcpay = async () => {
      if (state.finalPayFee == "0.00" || state.finalPayFee <= 0) {
        Toast({ message: "请选择或输入充值金额", position: "bottom" });
        return;
      }
      // 必须加入提示, 预下单网络延时较大时友好提醒用户
      Toast.loading({
        message: "请稍后...",
        forbidClick: true,
        duration: 0,
      });
      try {
        const { result } = await prepareIcbcRecharge(state.finalPayFee * 100);
        window.location.href = result.redirect_url;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({
          message: msg ? msg : "前端脚本异常/预下单失败",
          position: "bottom",
        });
        if (!msg) console.log(error);
      }
    };

    const signaturePage = async () => {
      try {
        const { result } = await signaturePack(location.href.split("#")[0]);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "chooseWXPay"],
        });
        wx.ready(() => {
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          Toast(res.errMsg);
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({
          message: msg ? msg : "前端脚本异常/页面签名失败",
          position: "bottom",
        });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      signaturePage();
    });

    return { MODULES, state, changeSelect, customfee, wxpay, icbcpay };
  },
};
</script>
